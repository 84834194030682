import React from 'react';

export default function Page404() {
  const data = {
    notFoundPageHtmlTitle: '404 - Page not found!',
    notFoundPageTitle: 'Oops! Are you lost?',
    notFoundPageBody:
      "We cannot find the page you're looking for. Better luck next time!",
  };

  return (
    <div>
      <h1>{data.notFoundPageHtmlTitle}</h1>
      <p>{data.notFoundPageBody}</p>
    </div>
  );
}
